/*
Copyright 2019 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

@import '../commons/index.css';

.spectrum-Rule {
  /* Show the overflow for hr in Edge and IE. */
  overflow: visible;

  border: none;
  border-width: var(--spectrum-rule-medium-height);
  border-radius: var(--spectrum-rule-medium-height);

  /* hr elements are given a default margin, reset it so that flex can work */
  margin: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  align-self: stretch;
}

.spectrum-Rule--large {
  border-radius: var(--spectrum-rule-large-border-radius);
}

.spectrum-Rule--medium {
  border-radius: var(--spectrum-rule-medium-border-radius);
}

.spectrum-Rule--small {
  border-radius: var(--spectrum-rule-small-border-radius);
}

.spectrum-Rule--horizontal {
  &.spectrum-Rule--large {
    block-size: var(--spectrum-rule-large-height);
  }

  &.spectrum-Rule--medium {
    block-size: var(--spectrum-rule-medium-height);
  }

  &.spectrum-Rule--small {
    block-size: var(--spectrum-rule-small-height);
  }
}

.spectrum-Rule--vertical {
  height: auto;
  /* expected usage, people need to set height 100% if they aren't using flex on the container
   * if they *ARE* using flex, then they need to either have align-items stretch OR align-self stretch
   */
  &.spectrum-Rule--large {
    inline-size: var(--spectrum-rule-large-height);
  }

  &.spectrum-Rule--medium {
    inline-size: var(--spectrum-rule-medium-height);
  }

  &.spectrum-Rule--small {
    inline-size: var(--spectrum-rule-small-height);
  }
}
